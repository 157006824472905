// @import 'carbon-components/scss/globals/scss/styles.scss';
@import '~@carbon/styles';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

legend.cds--label {
  font-size: 1rem !important;
}

.app-notify {
  position: fixed;
  right: 30px;
  z-index: 9001;
  bottom: 30px;
  transition: all .3s ease;
}

.cds--form-item {
  margin-bottom: .5em;
}